import { StoreEntityType } from 'ngx-unificator/store';
import { Observable, of } from 'rxjs';

export interface Xmas {
  page$: Observable<XmasPage>;
  currentBonus: CurrentBonusItem;
  currentSleighItemIndex: number;
  currentSleighItem: SleighDataItem;
  currentPrize: CurrentPrizeItem;
  sleighData: SleighDataItem[];
  defaultSleighArray: number[];
  isUserCanParticipateInPromo: boolean;
  isUserCanPartiallyParticipateInPromo: boolean;
  isUserAlreadyActivatedPromoLootbox: boolean;
  isSpinBonus: boolean;
  weeklyCompletedBonusesCount: number;
  weeklyCompletedBonusesLimit: number;
  isWeeklyMissionsCompleted: boolean;
  isLoadedPromo: boolean;
  bonusTitleString: string;
}

export interface XmasPage {
  Title: string;
  Description: string;
  SleighPrize: string;
  SleighRule: string;
  SleighPrizeTitle: string;
  SleighWeeklyPrizeDesc: string;
  ScoredLootboxTitle: string;
  WinLootboxTitle: string;
  WinSimpleBonusTitle: string;
  WinInformerTitle: string;
  Prizes: { prize: number | string }[];
  HowWorksTitle: string;
  HowWorksSteps: { title: string, desc: string }[];
  GamesTitle: string;
  GamesDesc: string;
  TournamentsTitle: string;
  HowWorksTerms: string;
  GeneralTerms: string;
  SleighData: SleighDataItem[];
  unpublishAt: any;
  publishAt: any;
  StartPromoDate: string;
  SecretFuelModalFirstTitle: string;
  SecretFuelModalSecondTitle: string;
  OpenLootboxTitle: string;
  TryTomorrowTitle: string;
  OtherPromotionsTitle: string;
  WeeklyMissionTitle: string;
  VisitAccountTitle: string;
}

export interface CurrentBonusItem {
  fs?: any;
  cash?: any;
  welcomeLootbox?: any;
  promoLootbox?: any;
}

export interface CurrentPrizeItem {
  fs_count?: number;
  cash?: any;
}

export interface SleighDataItem {
  date: string;
  short_date: string;
  locked?: boolean;
  taken?: boolean;
  currentDayIndex?: boolean;
  prizeTitle?: string;
  isCelebratedDay?: boolean;
}

export const xmasStore: StoreEntityType<Xmas> = {
  defaultSleighArray: [1, 2, 3, 4, 5, 6, 7, 8]
    .flatMap((item, index, array) =>
      (index === array.length - 1 ? Array(3).fill(item) : Array(4).fill(item))),
  page$: of(null),
  sleighData: null,
  currentSleighItemIndex: null,
  currentSleighItem: null,
  currentBonus: null,
  currentPrize: null,
  isUserCanParticipateInPromo: false,
  isUserCanPartiallyParticipateInPromo: false,
  isUserAlreadyActivatedPromoLootbox: false,
  isSpinBonus: false,
  weeklyCompletedBonusesCount: 0,
  weeklyCompletedBonusesLimit: 4,
  isWeeklyMissionsCompleted: false,
  isLoadedPromo: false,
  bonusTitleString: 'xmas',
};

export type XmasStoreEntity = typeof xmasStore;
