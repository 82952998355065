import { signal, InjectionToken, APP_INITIALIZER, Injector, PLATFORM_ID, effect, inject, computed, DestroyRef } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * Creates a new `StoreEntity` object by merging the provided `data` object with the `StoreEntity` interface.
 * If the `data` object already has a `__system` property, it is preserved with its existing values for `debug`, `loaded`, and `error`.
 * Otherwise, a new `__system` object is created with `null` values for those properties.
 * @param data - The object to be merged with the `StoreEntity` interface.
 * @returns The merged `StoreEntity` object.
 */
const createStoreEntity = data => {
  if (data.__system) {
    data.__system = {
      persistence: data?.__system?.persistence ?? null,
      debug: data?.__system?.debug ?? null,
      loaded: data?.__system?.loaded ?? null,
      error: data?.__system?.error ?? null
    };
  } else {
    data.__system = {
      persistence: null,
      debug: null,
      loaded: null,
      error: null
    };
  }
  return data;
};
const storeList = signal({});
const storeCreateEmiter$ = new Subject();
const STORE_PERSISTENCE_KEY = '__SIGNALS_STORE--';
/**
 * Retrieves a store instance by its name, and optionally registers a callback effect.
 * @param storeName - The name of the store to retrieve.
 * @param callbackEffect - An optional callback function to register as an effect for the store.
 * @returns The store instance, or throws an error if the store is not found.
 */
const useStore = (storeName, callbackEffect) => {
  if (storeName && storeList()[storeName]) {
    if (callbackEffect) {
      storeList()[storeName].onEffect(callbackEffect);
    }
    return storeList()[storeName];
  } else {
    throw new Error(`Store ${storeName} not found`);
  }
};
async function useStoreAwait(storeName, callbackEffect) {
  return new Promise((resolve, reject) => {
    if (storeName && storeList()[storeName]) {
      if (callbackEffect) {
        storeList()[storeName].onEffect(callbackEffect);
      }
      resolve(storeList()[storeName]);
    } else {
      const subscription = storeCreateEmiter$.subscribe(e => {
        if (storeName && storeList()[storeName]) {
          subscription.unsubscribe();
          if (callbackEffect) {
            storeList()[storeName].onEffect(callbackEffect);
          }
          resolve(storeList()[storeName]);
        }
      });
    }
  });
}
function initStore(injector, storeList, options = {}) {
  return () => {
    Object.keys(storeList).forEach(e => {
      createGlobalStore(injector, storeList[e], e, options);
    });
    return true;
  };
}
const STORE_LIST = new InjectionToken('StoreList');
const STORE_OPTIONS = new InjectionToken('StoreOptions');
/**
 * Provides a store initializer that can be used to register a set of stores and their options.
 * This initializer is designed to be used with the Angular dependency injection system.
 *
 * @param storeList - An optional object containing the stores to be registered.
 * @param options - An optional object containing the store options.
 * @returns An array of providers that can be used to register the store initializer.
 */
const provideStoreInitializer = (storeList, options = {}) => {
  return [{
    provide: STORE_LIST,
    useValue: storeList
  }, {
    provide: STORE_OPTIONS,
    useValue: options
  }, {
    provide: APP_INITIALIZER,
    useFactory: (injector, storeList, options) => initStore(injector, storeList, options),
    deps: [Injector, STORE_LIST, STORE_OPTIONS],
    multi: true
  }];
};
function createDebugEffectMessage(storeName, storeSignal) {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(2, '0').slice(0, 2);
  console.log('%c%s%c%s%c%s%c%s', 'color: #da70d6; background: #212836;', `\n Store: ${storeName} Effect `, 'color: yellow; background: #212836;', `\n Time: ${hours}:${minutes}:${seconds}.${milliseconds} `, 'color: #71b1fe; background: #212836;', '\n State: ', 'color: #23c668; background: #212836;', JSON.stringify(storeSignal(), null, 2));
}
function createDebugMessage(storeName, message) {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(2, '0').slice(0, 2);
  console.log('%c%s%c%s%c%s%c%s', 'color: #da70d6; background: #212836;', `\n Store: ${storeName} `, 'color: yellow; background: #212836;', `\n Time: ${hours}:${minutes}:${seconds}.${milliseconds} `, 'color: #71b1fe; background: #212836;', '\n Message: ', 'color: #23c668; background: #212836;', `${message} `);
}

/**
 * Creates a new global store with the provided store item, store name, and optional configuration options.
 * The store is added to the global store list, and a reference to the store is returned.
 * @param injector - The injector instance to use for the store.
 * @param storeItem - The initial data for the store.
 * @param storeName - The unique name of the store.
 * @param options - Optional configuration options for the store, including a debug flag.
 * @returns A reference to the created store.
 */
const createGlobalStore = (injector, storeItem, storeName, options = {}) => {
  const store = new CreateGlobalStoreClass(injector, createStoreEntity(storeItem), storeName, {
    ...options,
    debug: storeItem?.__system?.debug || options.debug,
    persistence: storeItem?.__system?.persistence || options.persistence || null
  });
  storeList.update(prev => {
    return {
      ...prev,
      [storeName]: store
    };
  });
  return storeList()[storeName];
};
/**
 * Creates a new store instance with the provided store item, store name, and optional configuration options.
 *
 * @param storeItem - The initial data for the store.
 * @param storeName - The unique name of the store.
 * @param options - Optional configuration options for the store, including a debug flag.
 * @returns The created store instance.
 */
const createStore = (storeItem, storeName, options = {}) => {
  const store = new CreateStoreClass(createStoreEntity(storeItem), storeName, {
    ...options,
    debug: storeItem?.__system?.debug || options.debug,
    persistence: storeItem?.__system?.persistence || options.persistence || null
  });
  storeList.update(prev => {
    return {
      ...prev,
      [storeName]: store
    };
  });
  storeCreateEmiter$.next(storeName);
  return storeList()[storeName];
};
/**
 * Represents a global store item in the application, responsible for managing the lifecycle of the store,
 * including creating a writable signal for the store data, subscribing to changes, and providing methods
 * to destroy the store and its associated effect.
 * @param injector - The injector instance to be used for the store effect.
 * @param storeItem - The initial data for the store.
 * @param storeName - The unique name of the store.
 * @param options - Optional configuration options for the store, including a debug flag to enable logging.
 */
class CreateGlobalStoreClass {
  constructor(injector, storeItem, storeName, options = {}) {
    this._callbacksEffect = [];
    this._platformId = null;
    this._platformId = injector.get(PLATFORM_ID);
    this._isBrowser = isPlatformBrowser(this._platformId);
    this._isPersistence = !!(options?.persistence ?? false);
    this._persistenceType = options?.persistence;
    this._isDebug = options?.debug ?? false;
    this.storeName = storeName;
    this.storeSignal = signal(this._resolveStoreGetPersistence(storeName) ? {
      ...storeItem,
      ...this._resolveStoreGetPersistence(storeName)
    } : storeItem);
    if (options.debug) {
      createDebugMessage(this.storeName, 'Store inited');
    }
    this._initStoreEffect(injector);
  }
  /**
   * Retrieves the persisted store data from the browser's localStorage.
   * @param storeName - The name of the store to retrieve the persisted data for.
   * @returns The persisted store data, or `null` if it cannot be retrieved.
   */
  _resolveStoreGetPersistence(storeName) {
    if (this._isBrowser && this._isPersistence) {
      try {
        return JSON.parse(window[this._persistenceType]?.getItem(STORE_PERSISTENCE_KEY + storeName) || '{}') || null;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
  /**
   * Resolves the persistence of the store item by storing it in the browser's localStorage.
   * If the store is marked as persistent and the current environment is a browser, the store item is serialized
   * to JSON and stored in localStorage using the store name as the key.
   * If an error occurs during the localStorage operation, it is logged to the console.
   * @param storeName - The name of the store.
   * @param storeItem - The store item to be persisted.
   */
  _resolveStoreSetPersistence(storeName, storeItem) {
    if (this._isBrowser && this._isPersistence) {
      try {
        window[this._persistenceType].setItem(STORE_PERSISTENCE_KEY + storeName, JSON.stringify(storeItem));
      } catch (error) {
        console.error(error);
      }
    }
  }
  /**
   * Initializes the store effect, which:
   * - Resolves the store persistence for the current store name
   * - Calls all registered effect callbacks with the current store data
   * - Logs the current store effect value if debug mode is enabled
   */
  _initStoreEffect(injector) {
    this._storeEffect = effect(() => {
      const data = this.storeSignal();
      this._resolveStoreSetPersistence(this.storeName, data);
      this._callbacksEffect.forEach(cb => cb(data));
      if (this._isDebug) {
        createDebugEffectMessage(this.storeName, this.storeSignal);
      }
    }, {
      injector
    });
  }
  /**
   * Registers a callback function that will be executed when the store effect is destroyed.
   * @param callback - The function to be called when the store effect is destroyed.
   */
  onEffect(callback) {
    this._callbacksEffect.push(callback);
  }
  /**
   * Destroys the effect associated with this store instance.
   */
  destroyEffect() {
    this._storeEffect.destroy();
    this._callbacksEffect = [];
    if (this._isDebug) {
      createDebugMessage(this.storeName, 'Effect destroyed');
    }
  }
  /**
   * Destroys the store instance by:
   * - Setting the store signal to `null`
   * - Destroying the associated effect
   * - Removing the store from the global `storeList`
   */
  destroyStore() {
    this.storeSignal.set(null);
    this.destroyEffect();
    storeList.update(prev => {
      delete prev[this.storeName];
      return prev;
    });
    if (this._isDebug) {
      createDebugMessage(this.storeName, 'Store destroyed');
    }
  }
}
/**
 * Represents a class that creates and manages a store instance.
 * The `CreateStoreClass` is responsible for:
 * - Initializing a store with a given item and name
 * - Providing a writable signal to access and update the store data
 * - Registering callbacks to be called when the store data changes
 * - Destroying the store and its associated effect when needed
 */
class CreateStoreClass {
  constructor(storeItem, storeName, options = {}) {
    this._callbacksEffect = [];
    this._platformId = inject(PLATFORM_ID);
    this._isBrowser = isPlatformBrowser(this._platformId);
    this._isPersistence = !!(options?.persistence ?? false);
    this._persistenceType = options?.persistence;
    this._isDebug = options?.debug ?? false;
    this.storeName = storeName;
    this.storeSignal = signal(this._resolveStoreGetPersistence(storeName) ? {
      ...storeItem,
      ...this._resolveStoreGetPersistence(storeName)
    } : storeItem);
    if (options?.debug) {
      createDebugMessage(this.storeName, 'Store inited');
    }
    this._initStoreEffect();
  }
  /**
   * Retrieves the persisted store data from the browser's localStorage.
   * @param storeName - The name of the store to retrieve the persisted data for.
   * @returns The persisted store data, or `null` if it cannot be retrieved.
   */
  _resolveStoreGetPersistence(storeName) {
    if (this._isBrowser && this._isPersistence) {
      try {
        return JSON.parse(window[this._persistenceType]?.getItem(STORE_PERSISTENCE_KEY + storeName) || '{}') || null;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
  /**
   * Resolves the persistence of the store item by storing it in the browser's localStorage.
   * If the store is marked as persistent and the current environment is a browser, the store item is serialized
   * to JSON and stored in localStorage using the store name as the key.
   * If an error occurs during the localStorage operation, it is logged to the console.
   * @param storeName - The name of the store.
   * @param storeItem - The store item to be persisted.
   */
  _resolveStoreSetPersistence(storeName, storeItem) {
    if (this._isBrowser && this._isPersistence) {
      try {
        window[this._persistenceType].setItem(STORE_PERSISTENCE_KEY + storeName, JSON.stringify(storeItem));
      } catch (error) {
        console.error(error);
      }
    }
  }
  /**
   * Initializes the store effect, which:
   * - Resolves the store persistence for the current store name
   * - Calls all registered effect callbacks with the current store data
   * - Logs the current store effect value if debug mode is enabled
   */
  _initStoreEffect() {
    this._storeEffect = effect(() => {
      const data = this.storeSignal();
      this._resolveStoreSetPersistence(this.storeName, data);
      this._callbacksEffect.forEach(cb => cb(data));
      if (this._isDebug) {
        createDebugEffectMessage(this.storeName, this.storeSignal);
      }
    });
  }
  /**
   * Registers a callback function that will be executed when the store effect is destroyed.
   * @param callback - The function to be called when the store effect is destroyed.
   */
  onEffect(callback) {
    this._callbacksEffect.push(callback);
  }
  /**
   * Destroys the effect associated with this store instance.
   */
  destroyEffect() {
    this._storeEffect.destroy();
    this._callbacksEffect = [];
    if (this._isDebug) {
      createDebugMessage(this.storeName, 'Effect destroyed');
    }
  }
  /**
   * Destroys the store instance by:
   * - Setting the store signal to `null`
   * - Destroying the associated effect
   * - Removing the store from the global `storeList`
   */
  destroyStore() {
    this.storeSignal.set(null);
    this.destroyEffect();
    storeList.update(prev => {
      delete prev[this.storeName];
      return prev;
    });
    if (this._isDebug) {
      createDebugMessage(this.storeName, 'Store destroyed');
    }
  }
}

/**
 * Creates a combined store selector that combines the state from multiple stores.
 * @param storeNameList - An array of store names to combine.
 * @param combiner - An optional function to combine the values from the individual stores.
 * @returns A computed signal that represents the combined state from the specified stores.
 */
const createSelectorStore = (storeNameList, combiner) => {
  const storeList = [];
  storeNameList.forEach(storeName => {
    if (typeof storeName === 'string') {
      storeList.push(useStore(storeName));
    } else {
      storeList.push(storeName);
    }
  });
  if (combiner) {
    const combinedSignal = computed(() => {
      const values = storeList.map(store => store.storeSignal());
      return combiner(...values);
    });
    return combinedSignal;
  } else {
    const combinedSignal = computed(() => {
      const values = {};
      storeList.forEach(store => {
        values[store.storeName] = store.storeSignal();
      });
      return values;
    });
    return combinedSignal;
  }
};

/**
 * Synchronizes the value of a Subject or Reactive Subject to a Writable Signal.
 * @param from - The Subject or Reactive Subject to synchronize the value from.
 * @param to - The Writable Signal to synchronize the value to.
 * @returns The Writable Signal that the value was synchronized to.
 * @throws Error if `from` or `to` is `null`.
 */
function syncSignalValue(from, to) {
  if (from && to) {
    const destroyRef = inject(DestroyRef);
    const sub = from?.subscribe(e => {
      to?.set(e);
    });
    try {
      destroyRef?.onDestroy(() => {
        sub?.unsubscribe();
      });
    } catch (error) {
      sub?.unsubscribe();
    }
    return to;
  } else {
    throw new Error('syncSignalValue: from or to is null');
  }
}
/**
 * Synchronizes the value of a writable signal to an observable subject.
 * @param from - The writable signal to synchronize from.
 * @param to - The observable subject to synchronize to.
 * @returns The original writable signal.
 * @throws Error if `from` or `to` is `null`.
 */
function syncObservableValue(from, to) {
  if (from && to) {
    const destroyRef = inject(DestroyRef);
    const sub = toObservable(from)?.subscribe(e => {
      to.next(e);
    });
    try {
      destroyRef?.onDestroy(() => {
        sub?.unsubscribe();
      });
    } catch (error) {
      sub?.unsubscribe();
    }
    return from;
  } else {
    throw new Error('syncObservableValue: from or to is null');
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { CreateGlobalStoreClass, CreateStoreClass, STORE_PERSISTENCE_KEY, createGlobalStore, createSelectorStore, createStore, createStoreEntity, provideStoreInitializer, storeCreateEmiter$, storeList, syncObservableValue, syncSignalValue, useStore, useStoreAwait };
